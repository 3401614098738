import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

$(document).foundation();

$('#kbh_contact_form').submit(function(e) {
    var captcha_response = grecaptcha.getResponse();
    if(captcha_response.length == 0) {
        document.getElementById('g-recaptcha-error').innerHTML = '<span style="color:red;">Du skal Klik here.</span>';
        return false;
    }
    e.preventDefault();
    var name = $("input#name").val();
    var email = $("input#email").val();
    var message = $("textarea#message").val();

    var dataString = 'name=' + name +
        '&email=' + email +
        '&message=' + message +
        '&g-recaptcha-response=' + captcha_response;

    $.ajax({
        type: "POST",
        url: "php/mail.php",
        data: dataString,
        success: function () {
            $('.contactform').html("<div id='thanks'></div>");
            $('#thanks').html("<h3>Tak!</h3>").append("<p>  Vi vil kontakte dig snarest muligt. </p>").hide().fadeIn(1500);
        },
    }); //ajax call ends
    return false;
});

